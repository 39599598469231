import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
  SAModal,
  SASearch,
  SAAccordion,
  SACheckbox,
  SAUXTheme,
  SASpinner,
  SASnackbar,
  SAUX360Theme,
  SARadioGroup,
  SARadio,
  SAPopover
} from '@saux/design-system-react';
import { makeStyles } from '@material-ui/core/styles';
import './ContactType.css';
import ContactData from '../../Model/ContactModel';
import ModalAddNewContactTypeAgencyCodes from '../../modal/ModalAddNewContactTypeAgencyCodes/ModalAddNewContactTypeAgencyCodes';
import ModalAddContactTypeAlerts from '../../modal/ModalAddContactTypeAlerts/ModalAddContactTypeAlerts';
import env from '../../../env_config';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { useHistory } from "react-router-dom";
import {
  AgencyCode,
  AppContextProps,
  AppGenPayloadAdd,
  AppGenPayloadRemove,
} from '../../../interfaces/interfaces';
import {
  Container,
  Header,
  StyledHeader,
  ContactTypeSectionWrapper,
  StyledSACard,
  ContactTypeAccordingViewText,
  AgencyLocations,
  AgencyLocationWrapper,
  AgencyLocationPanel,
  LearnMoreContainer,
  StyledLearnMoreText,
  StyledLearnMoreTitleText,
  LearnMoreLineSpacing,
  LearnMoreContentContainer,
  LearnMoreHeader,
  ContactTypes,
  LearnMoreDiv,
  ProfileSpinnerContainer,
  DisabledContinueButton,
  StyledSAAddContactTypeButton,
  StyledAdContactTypeCheckbox,
  AddContactTypeFooterButtons,
  CardValidation,
  ExistingContactType,
  PersonalLineQuestion,
  PersonalLineAlert,
  AddContactTypeContainer,
  ExistingContactTypes,
  ConfirmContactTypeChanges,
  ConfirmContactTypeChangesModal,
  ConfirmContactTypeChangesModalTitle,
  ConfirmContactTypeChangesModalFooter,
  ConfirmContactTypeChangesModalBody,
  ConfirmContactTypeChangesModalText,
  HelpTextIconButton
} from '../AgencyContactDetailsStyles/AgencyContactDetailsStyles';
import ConfirmEditModal from '../../ConfirmEditModal/ConfirmEditModal';
import ModalContactTypeRemove from '../../modal/ModalContactTypeRemove/ModalContactTypeRemove';
import { encrypt, decrypt } from '../../../utils/crypto';
import ModalCancelAlert from '../../modal/ModalCancelAlert/ModalCancelAlert';
import ModalContactAgencyOperations from '../../modal/ModalContactAgencyOperations/ModalContactAgencyOperations';
import ReactDOM from 'react-dom';
import ModalLastContactType from '../../modal/ModalLastContactType/ModalLastContactType';
import ModalApplicationGenerated from '../../modal/ModalApplicationGenerated/ModalApplicationGenerated';
import ModalSpinner from '../../modal/ModalSpinner/ModalSpinner';
import ModalPendingEdits from '../../modal/ModalPendingEdits/ModalPendingEdits';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import { AdminPersonDetailsQuery, IsEditableQuery, IsremovableQuery, TerminateProducerAppointmentsMutation, UpdatePendingPersonContactTypeMutation } from '../../../API';
import handleGraphQLErrors from '../../../utils/handleGraphQLErrors';
import simpleGTMDataLayer from '../../../utils/GTMHelpers/simpleGTMDataLayer';
import { checkAuthStatus } from '../../../utils/utils';
import ModalLicenceProducerApplication from '../../modal/ModalLicenceProducerApplication/ModalLicenceProducerApplication';

type Position = 'auto' | 'top-start' | 'top' | 'top-end' | 'right' | 'bottom-start' | 'bottom' | 'bottom-end' | 'left';
type Offset = [number, number];

const mobileWidth = 600;

type RemoveContactType = {
  title: string;
  type: string;
  action: string;
  agency_codes?: [];
}

type StyleProps = {
  theme?: SAUXTheme;
  isCheckboxesValid?: boolean;
};

const Popover = styled.div`
  ${({ theme }: StyleProps) => {
  return `
      display: flex;
      flex-direction: column;
      color: hsla(220, 38%, 97%, 1);
      font-size: ${theme?.font.primary.size.smallTEMP};
      font-weight: ${theme?.font.primary.weight.normal};
      line-height: 16px;
      background-color: hsla(213, 30%, 35%, 1);
      border: 1px solid hsla(213, 30%, 35%, 1);
      box-sizing: border-box;
      box-shadow: 0px 0px 5px rgba(0, 32, 56, 0.22);
      border-radius: 4px;
      padding: 15px;
      width: 360px;
    `;
}};
`;

const HelpContainer = styled.div`
  display: flex;
  flex-directino: column;
`;

const HelpIcon = styled.div`
  display: flex;
  padding-right: 10px;
  align-items: flex-start;
`;

const HelpText = styled.div`
  display: flex;
  align-items: flex-start;
`;

const HelpTextContent = styled.div`

`;

const HelpTextHeader = styled.div`
  font-weight: ${({ theme }: StyleProps) => theme?.font.primary.weight.bold};
  padding-bottom: 10px;
`;

const ErrorMessage = styled.div`
  margin-top: 8px;
  padding-left: 15px;
`;

const StyledTrashIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 24px;
  margin-left: 40px;
  margin-top: 17px;
`;

const StyledTrashButton = styled (SAButton)`
  ${({theme}: StyleProps) => {
    return `
      &:hover {
        background-color: white;
        cursor: pointer;
        svg * {
          fill: #B00020;
        }
      }

      svg {
        color: rgba(176, 0, 32, 1);
      }

      &:disabled {
        &:hover {
          cursor: not-allowed;
          svg * {
            fill: rgb(150, 150, 150);
          }
        }
        svg * {
          fill: rgb(224, 224, 224);
        }
      }
    `;
  }};
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Modal = styled(SAModal).attrs(({display}) => ({
  display,
}))`
  ${({display}) => {
    return `
      display: ${display ? display : 'flex'};
      flex-direction: row;
      top: 50%;
      left: 50%;
      overflow: auto;
    `;
  }}
`;

const AccordionContent = styled.div``;

const StyledAccordions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  
  div:first-child {
    flex-grow: 1;
  }
  div:nth-child(2) {
    flex-grow: 0;
    flex-shrink: 1;
  }
  
`;

const AgencyCodeContent = styled.div`
  ${({ theme }: StyleProps) => {
  return `
      max-height: 290px;
      overflow-y: auto;

      @media only screen and (max-width: ${mobileWidth}px) {
        margin: ${theme?.size.spacer.small} 0 ${theme?.size.spacer.small} 0;
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 92px;
        border: none;
      }
    
      &::-webkit-scrollbar-track {
          background: #F0EFEF;
          border-radius: 7px;
      }
    
      &::-webkit-scrollbar-thumb {
          background: #8A8A8A;
          border-radius: 7px;
          box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      }
    `;
}};
`;

const ContinueButton = styled.div`
  ${({ theme }: StyleProps) => {
  return `
      display: flex;
      justify-content: flex-end;
      margin: calc(${theme?.size.spacer.medium} + 5px) 0;
    `;
}};
`;

const AgencyCodesContainer = styled.div.attrs(({isCheckboxesValid}: StyleProps) => ({
  isCheckboxesValid,
}))`
  ${({ theme, isCheckboxesValid }: StyleProps) => {
    const color = isCheckboxesValid ? '#D8DFEC' : `${theme?.color.alert.background}`;
    return `
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      border: 1px solid ${color};

      span {
        &.heading {
          display: flex;
          padding: ${theme?.size.spacer.small} calc(${theme?.size.spacer.medium} + 5px);
          margin: ${theme?.size.spacer.medium} ${theme?.size.spacer.small} 0 ${theme?.size.spacer.small};
          font-weight: ${theme?.font.primary.weight.bold};
          background-color: hsl(220,38%,97%);

          @media only screen and (max-width: ${mobileWidth}px) {
            margin: 0;
          }
        }
      }
    `;
}};
`;

const AgencyCodesCheckboxes = styled.div`
  ${({ theme }: StyleProps) => {
  return `
      margin: ${theme?.size.spacer.small};
      max-height: 290px;
      overflow-y: auto;

      @media only screen and (max-width: ${mobileWidth}px) {
        margin: ${theme?.size.spacer.small} 0 ${theme?.size.spacer.small} 0;
      }

      .saCheckboxWrapper {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 15px;
        z-index: 0;
        .saCheckboxLabel {
          color: ${theme?.colors.text};
        }
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 92px;
        border: none;
      }
    
      &::-webkit-scrollbar-track {
          background: #F0EFEF;
          border-radius: 7px;
      }
    
      &::-webkit-scrollbar-thumb {
          background: #8A8A8A;
          border-radius: 7px;
          box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      }
    `;
}};
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: ${mobileWidth}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Search = styled.div`
  ${({ theme }: StyleProps) => {
  return `
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;

      .saInputWrapper {
        margin: 0 0 ${theme?.size.spacer.medium} 0;
      }

      .textLikeInput {
        padding-top: 0;
        width: initial;
        font-size: ${theme?.font.primary.size.medium};
      }

      ul {
        align-items: center;
        span {
          button {
            margin-bottom: 0;
          }
        }
        button {
          margin-bottom: 5px;
        }
      }
    `;
}};
`;

const SelectAllButton = styled(SAButton)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-shrink: 0;
      margin: 0 0 ${theme?.size.spacer.medium} ${theme?.size.spacer.medium};

      @media only screen and (max-width: ${mobileWidth}px) {
        margin: 0 0 ${theme?.size.spacer.medium} 0;
      }
    `;
  }};
`;

const CrossHierarchyText = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      padding-top: ${theme?.size.spacer.medium};
      font-size: ${theme?.font.primary.size.medium};
      font-weight: ${theme?.font.primary.weight.bold};
      color: ${theme?.colors.text};
    `;
  }};
`;

export const StyledError = styled.span`
  ${({theme}: StyleProps) => {
    return `
      color: ${theme?.color.alert.background};
      display: block;
      font-size: 14px;
      padding-top: 10px;
      position: absolute;
    `;
  }};
`;

export default function FullWidthGrid(props: any) {
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;
  const isAdmin: boolean = decrypt(localStorage.getItem('isAdmin')) === 'true' ? true : false;
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [previouslyExpanded, setPreviouslyExpanded] = useState<string | boolean>(false);
  const [nextExpanded, setNextExpanded] = useState<string | boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [onSaveChanges, setOnSaveChanges] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isCheckboxesValid, setIsCheckboxesValid] = useState<boolean>(true)
  const [contactElements, setContactElements] = useState([]);
  const [orgContactElements, setOrgContactElements] = useState([]);
  const [filteredContactTypes, setFilteredContactTypes] = useState([]);
  const [savedAgencyAddress, setSavedAgencyAddress] = useState<any[]>([]);
  const [agencyCodeMap, setAgencyCodeMap] = useState<any[]>([]);
  const [contactTypesData, setContactTypesData] = useState<any[]>([]);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [displayAddContactTypeModal, setDisplayAddContactTypeModal] = useState<boolean>(false);
  const [newContacts, setNewContacts] = useState<any[]>([]);
  const [learnMore, setLearnMore] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [nextModal, setNextModal] = useState<boolean>(false);
  const [removeType, setRemoveType] = useState<string>("");
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const rememberRefElem = useRef<any>(null);
  const [multipleIn, setMultipleIn] = useState<boolean>(false);
  const [contactTypeRemoveBanner, setContactTypeRemoveBanner] = useState<boolean>(false);
  const [personalLineError, setPersonalLineError] = useState<boolean>(false);
  const [personalLineQuestion, setPersonalLineQuestion] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [removeAgencyCodeModal, setRemoveAgencyCodeModal] = useState<boolean>(false);
  const [addRemoveModalButtomMode, setAddRemoveModalButtomMode] = useState<boolean>(false);
  const [removeContactType, setRemoveContactType] = useState<RemoveContactType>();
  const changedBy = decrypt(localStorage.getItem('personId'));
  const [appGenModalType, setAppGenModalType] = useState<'add' | 'remove' | undefined>(undefined);
  const firstName = decrypt(localStorage.getItem('selectedFirstName'));
  const lastName = decrypt(localStorage.getItem('selectedLastName'));
  const [pendingEdits, setPendingEdits] = useState<boolean>(false);
  const history = useHistory();
  const [crossHierarchy, setCrossHierarchy] = useState<boolean>(props.crossHierarchy);
  const [displayLPModalAlert, setDisplayLPModalAlert] = useState<{adminName: string, requestedOn: string} | null>(null);

  // Popover Help Text
  const desktopPosition: Position = 'auto';
  const mobilePosition: Position = 'top-end';
  const desktopOffset: Offset = [0, 10];
  const mobileOffset: Offset = [0, 10];
  const [mobile, setMobile] = useState<boolean>(true)
  const [popover, setPopover] = useState<string | null>(null);
  let contactTypesAssigned = useRef('');
  const [position, setPosition] = useState<Position>(
    document.documentElement.clientWidth <= mobileWidth ? mobilePosition : desktopPosition,
  );
  const [offset, setOffset] = useState<Offset>(
    window.innerWidth <= mobileWidth ? mobileOffset : desktopOffset,
  );

  // Spinner
  let delay: ReturnType<typeof setTimeout>;
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [spinnerStatus, setSpinnerStatus] = useState<string>('hide');

  const spinner = (
    <ProfileSpinnerContainer display={spinnerStatus}>
      <SASpinner variant="circular-continuous" delay={0} color={SAUX360Theme.colors.blueGray600} size="20px" />
    </ProfileSpinnerContainer>
  );

  // Modals
  const [displayLearnMoreModal, setDisplayLearnMoreModal] = useState<boolean>(false);
  const [displayCancelAlert, setDisplayCancelAlert] = useState<boolean>(false);
  const [displayContactAgencyOperationsModal, setDisplayContactAgencyOperationsModal] = useState<boolean>(false);
  const [displayLastContactTypeModal, setDisplayLastContactTypeModal] = useState<boolean>(false);
  const [displayApplicationGeneratedModal, setDisplayApplicationGeneratedModal] = useState<boolean>(false);
  const [activeAgencyCodes, setActiveAgencyCodes] = useState<boolean>(false);
  const location = useLocation<AppContextProps>();
  const [selectAll, setSelectAll] = useState<boolean>(true);
  const personId = decrypt(localStorage.getItem('selectedPersonId'));
  const agencyCode = decrypt(localStorage.getItem('agency_code'));
  const data = [...ContactData.contacttype];
  const editState = props?.openEdit;
  const changeEditState = props?.change;
  // let contactTypeEvent = {};

  // loading spinner
  const [showModalSpinner, setShowModalSpinner] = useState<boolean>(false);

  // SnackBar
  const [showToastMessage, setShowToastMessage] = useState<boolean>(false);
  const [licensedProducerOnly, setLicensedProducerOnly] = useState<boolean>(false);
  const [suppressToastMessage, setSuppressToastMessage] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarTitle, setSnackbarTitle] = useState<string>('');
  const [snackbarSubtitle, setSnackbarSubtitle] = useState<string>('');

  // App Generated Modal
  const [payload, setPayload] = useState<AppGenPayloadAdd | null>(null);
  const [showAppGenModalSpinner, setShowAppGenModalSpinner] = useState<boolean>(false);

  // Alert Modals
  const [noAgencyCodes, setNoAgencyCodes] = useState<boolean>(false);
  const [onlyPrincipalContactType, setOnlyPrincipalContactType] = useState<boolean>(false);
  const [noContactTypesRemain, setNoContactTypesRemain] = useState<boolean>(false);

  data.sort((desc1, desc2) => {
    return desc1.description.localeCompare(desc2.description);
  });

  const formMethod = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = formMethod;

  const contactTypes = [
    {
      'id': '3',
      'name': 'Agency Admin',
      'checked': false
    }, {
      'id': '7',
      'name': 'Agency Staff',
      'checked': false
    }, {
      'id': '10',
      'name': 'Agency Manager',
      'checked': false
    }, {
      'id': '13',
      'name': 'Primary Claim Contact',
      'checked': false
    }, {
      'id': '14',
      'name': 'Primary Mail Recipient',
      'checked': false
    }, {
      'id': '36',
      'name': 'Accounting Manager',
      'checked': false
    }, {
      'id': '0',
      'name': 'Principal',
      'checked': false
    }, {
      'id': '37',
      'name': 'Licensed Producer',
      'checked': false
    }
  ];

  useEffect(() => {
    if (props?.isExpanded) {
      setExpanded(false);
    }
  }, [props?.isExpanded]);

  const removeHelpIcon = () => {
    const helpIcon = Array.from(document.getElementsByClassName('helpicon'));
    if (helpIcon.length > 0) {
      helpIcon.forEach((item: any) => {
          const parentElement = item.parentElement as HTMLElement;
          if (parentElement.innerText !== 'PRINCIPAL') {
            item.parentNode.removeChild(item);
          }
      });
    }
  }

  function handlePendingEditsClose() {
    if (!isAdmin) {
      const portalUrl = env['saconnect'];
      window.location.href = portalUrl;
    } else {
      history.push({
        pathname: `/agency-contacts`,
      });
    }
  }

  const getPrincipalHelpIcon = ()=> {
    setTimeout(() => {
      const element =  Array.from(document.getElementsByClassName('accordion-title'));
      const elementModal = document.querySelector(`label[for="Principal"]`);
      const helpIcon = document.getElementsByClassName('helpicon');
      const jsxElement: JSX.Element = (
        <HelpTextIconButton
          fullWidth={false}
          variant="link-small"
          label="Help Text"
          onClick={(e: any) => togglePopover(e, 'Principal')}
          onKeyDown={(e: any) => handleKeyDown(e, 'Principal')}
        >
          <SAIcon className="help-icon-svg" icon={SAIcons.information} size="20px" colorVariant="primary" onMouseOver={(e: any) => openPopover(e, 'Principal')} onMouseLeave={closePopover} />
        </HelpTextIconButton>
      );

      if (element !== null && helpIcon.length === 0) {
        element.forEach((item: any) => {
          if (item.innerHTML === 'Principal') {
            const parentElement = item.parentElement as HTMLElement;
            const divElement = document.createElement('div');
            divElement.classList.add('helpicon');
            ReactDOM.render(jsxElement, divElement);
            parentElement.appendChild(divElement);
          }
        });
      }
      if (elementModal !== null && helpIcon.length === 0) {
        if (elementModal.innerHTML === 'Principal') {
          const parentElement = elementModal.parentElement as HTMLElement;
          const divElement = document.createElement('div');
          divElement.classList.add('helpicon');
          ReactDOM.render(jsxElement, divElement);
          parentElement.appendChild(divElement);
        }
      }
    }, 100);
  };

  useEffect(() => {
    async function getAdminPersonDetails() {
      const adminPersonId =  decrypt(localStorage.getItem('personId'));

      const isAuthenticated: boolean = await checkAuthStatus();
      if (!isAuthenticated) {
        return;
      }
      const promisePersonData = API.graphql(
        graphqlOperation(
          queries.adminPersonDetails,
          {
            admin_person_id: adminPersonId,
            person_id: personId
          }
        ),
        {
          Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
        }
      ) as Promise<{ data: AdminPersonDetailsQuery }>;

      promisePersonData.then((res) => {
        if (res.data) {
          return res.data.adminPersonDetails;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200) {
          return res.body;
        } else {
          throw new Error('Something went wrong - 07');
        }
      })
      .then((res) => {
        if (res) {
          removeHelpIcon();
          setAgencyCodeMap(res.agency_code_map as any[]);
          setOnSaveChanges(false);
        } else {
          throw new Error('Something went wrong - 08');
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err); 
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });
    }

    getAdminPersonDetails();
  }, [onSaveChanges]);

  function openPopover(event: React.MouseEvent<HTMLElement>, type: string) {
    event.stopPropagation();
    setPopover(type);
    setReferenceElement(event.currentTarget);
  }

  function closePopover(event: React.MouseEvent<HTMLElement> | MouseEvent, index: number | null) {
    setReferenceElement(null);
  }

  function togglePopover(event: React.MouseEvent<HTMLElement>, privilege: string) {
    setPopover(privilege);
    event.stopPropagation();
    if (rememberRefElem.current === null) {
      setReferenceElement(event.currentTarget.firstChild as HTMLElement);
      rememberRefElem.current = event.currentTarget.firstChild;
    } else {
      setReferenceElement(null);
      rememberRefElem.current = null;
    }
  }

  function handleKeyDown(e: any, privilege: string) {
    setPopover(privilege);
    if (e.key === 'Tab') {
      e.stopPropagation();
      setReferenceElement(null);
      rememberRefElem.current = null;
    }
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      if (rememberRefElem.current === null) {
        setReferenceElement(e.currentTarget.firstChild as HTMLElement);
        rememberRefElem.current = e.currentTarget.firstChild;
      } else {
        setReferenceElement(null);
        rememberRefElem.current = null;
      }
    }
  }

  function handleClickApplicationGeneratedModalCancel(event: React.MouseEvent) {
    setDisplayApplicationGeneratedModal(!displayApplicationGeneratedModal);
    if (!licensedProducerOnly) {
      setSuppressToastMessage(false);
    }
  }

  function handleClickLPAlertModalCancel(event: React.MouseEvent) {
    setDisplayLPModalAlert(null);
    if (!licensedProducerOnly) {
      setSuppressToastMessage(false);
    }
  }

  function handleClickSendEmail() {
    setShowAppGenModalSpinner(true);
  }

  function handleEmailSent() {
    setShowAppGenModalSpinner(false);
  }

  function handleClickApplicationGeneratedModalClose(event: React.MouseEvent) {
    setDisplayApplicationGeneratedModal(!displayApplicationGeneratedModal);
    if (!licensedProducerOnly) {
      setSuppressToastMessage(false);
    }
  }
  function handleLicenseProducerApplication(data: {adminName: string, requestedOn: string}) {
    setDisplayApplicationGeneratedModal(!displayApplicationGeneratedModal);
    if (!licensedProducerOnly) {
      setSuppressToastMessage(false);
    }
    setDisplayLPModalAlert(data);
  }

  async function handleClick(
    e: React.MouseEvent<HTMLElement | MouseEvent> | React.KeyboardEvent,
    button: string,
    panel: string,
    type: string
  ) {
    // Be sure to add e.stopPropagation to prevent the accordion from toggling.
    e.stopPropagation();
    setPreviouslyExpanded(expanded);
    switch (button) {
      case "Edit":
        setSelectAll(selectAllDeselectAllButtonState(type));
        if (editState !== type && editState !== 'default') {
          setDisplayModal(true);
          localStorage.setItem('incoming', encrypt(type));
        } else {
          changeEditState(type);
        }
        setIsCheckboxesValid(true);
        setEditMode(true);
        setExpanded(panel);
        setPreviouslyExpanded(expanded);
        break;
      case "Cancel":
        changeEditState('default');
        setIsCheckboxesValid(true);
        setSelectAll(!selectAllDeselectAllButtonState(type));
        setEditMode(false);
        break;
      case "validate":
        setIsCheckboxesValid(validateCheckboxes(type));
        setSelectAll(!selectAllDeselectAllButtonState(type));
        break;
      case "SaveChanges":
        let values: any[] = [];
        const element: NodeListOf<HTMLElement> = document.getElementsByName(type);

        if (element !== null) {
          element.forEach((item: any) => {
            if (item.checked) {
              values.push(item.value);
            }
          })
        }

        const validCheckboxes = validateCheckboxes(type);
        setIsCheckboxesValid(validCheckboxes);

        const newType = type === 'Agency Manager' ? 'Agency/Office Manager' : type;

        const data = {
          "contact_type_desc": newType,
          "agency_cd": values,
          "updated_by": changedBy
        }

        if (validCheckboxes) {
          // Check to see if any agency codes are being removed.
          // If so, then call is removable API to confirm if we are allowed to remove before saving.
          let contact_type_id: string = '';
          let originalAgencyCodes: string[] = [];

          agencyCodeMap.forEach((addr) => {
            (addr.contact_type as string).split(',').forEach((contactType, index) => {
              if (contactType === type) {
                contact_type_id = (addr.contact_type_id as string).split(',')[index];
                originalAgencyCodes.push(addr.agency_code);
              }
            });
          });

          const removedAgencyCodes: string[] = [];
          originalAgencyCodes.forEach((agencyCode) => {
            if (!values.includes(agencyCode)) {
              removedAgencyCodes.push(agencyCode);
            }
          });

          if (removedAgencyCodes.length) {
            // We have deterined that an agency code is being removed.
            // We must now call is-removable API to confirm if we are allowed to remove before saving
            const adminPersonId =  decrypt(localStorage.getItem('personId'));

            const isAuthenticated: boolean = await checkAuthStatus();
            if (!isAuthenticated) {
              return;
            }
            const promiseIsRemovable = API.graphql(
              graphqlOperation(
                queries.isremovable,
                {
                  admin_person_id: adminPersonId,
                  contact_type: contact_type_id,
                  person_id: personId
                }
              ),
              {
                Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
              }
            ) as Promise<{ data: IsremovableQuery}>;

            promiseIsRemovable.then((res) => {
              if (res.data) {
                return res.data.isremovable;
              } else {
                handleGraphQLErrors(res);
              }
            })
            .then((res) => {
              if (res?.statusCode === 200 || res?.statusCode === 400) {
                return res.body;
              } else {
                throw new Error('Something went wrong - 09');
              }
            })
            .then((res) => {
              if (res) {
                if (res?.status === 'success') {
                  sendData(data);
                } else if (res?.status === 'error') {
                  let removeModalData: any = {};
                  removeModalData.title = res.reason;
                  removeModalData.type = res.contact_type;
                  removeModalData.action = res.status;
                  if (res.agency_codes && res.agency_codes.length > 0) {
                    const filteredAgencyCodes = res.agency_codes.filter((ac) => {
                      const acString = ac as string;
                      const indexOfFirstSpace = acString.indexOf(' ');
                      const agcyCode = acString.substr(0, indexOfFirstSpace);

                      if (removedAgencyCodes.includes(agcyCode)) {
                        return true;
                      } else {
                        return false;
                      }
                    });
                    removeModalData.agency_codes = filteredAgencyCodes;
                  } else {
                    removeModalData.agency_codes = [];
                  }
                  if (removeModalData.agency_codes.length) {
                    setShowModalSpinner(false);
                    setRemoveContactType(removeModalData);
                    setRemoveAgencyCodeModal(true);
                  } else {
                    sendData(data);
                  }
                }
              } else {
                throw new Error('Something went wrong - 10');
              }
            })
            .catch((err) => {
              if (err?.message) {
                console.error(err.message, err); 
              } else {
                handleGraphQLErrors(err);
              }
              window.location.href = "error";
            });
          } else {
            sendData(data);
          }
        }
        break;
      case "discard":
        const inComing = decrypt(localStorage.getItem('incoming'));
        if (inComing !== undefined) {
          changeEditState(inComing);
        }

        break;
      default:
        console.error(`A switch case is missing for button name '${button}'`);
    }
  }

  function getDisplayedContactTypes() {
    let displayed : any[] = [];
    contactTypes.map((ct: any) => {
      orgContactElements.forEach((el: any) => {
        if (el.name === ct.name) {
          ct.checked = true;
        }
        if (!displayed.includes(ct)) {
          displayed.push(ct);
        }
      })
    });
    const sortedContactTypes = displayed.sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return sortedContactTypes;
  }

  const validateCheckboxes = (typ: string) => {
    let isValid: boolean = false;
    const element: NodeListOf<HTMLElement> = document.getElementsByName(typ);
    if (element !== null) {
      element.forEach((item: any) => {
        if (item.checked) {
          isValid = true;
        }
      })
    }
    return isValid;
  }

  function getButtons(panel: string, type: string) {
    const startIcon = (
      <SAIcon
        icon={SAIcons.pencil}
        size={SAIconSize.small}
        colorVariant="secondary"
      />
    );
    if (type !== 'Principal' && !crossHierarchy) {
      if (isMobile) {
        return (
          <SAButton
            label="Edit"
            onClick={(e) => handleClick(e, "Edit", panel, type)}
            variant="primary-link-small"
            className={panel}
          >
            {startIcon}
          </SAButton>
        );
      }
      return (
        <SAButton
          label="Edit"
          onClick={(e) => handleClick(e, "Edit", panel, type)}
          variant="primary-link-small"
          startIcon={startIcon}
          textTransform="none"
        />
      );
    }
    if (type === 'Principal') {
      getPrincipalHelpIcon();
    }
  }

  function checkForContactType() {
    let errorBanner = false;
    orgContactElements.map((item: any) => {
      if (!item.checked ) {
        errorBanner = true;
      }
    })
    return errorBanner;
  }

  function getMe(e: any) {
    setContactTypeRemoveBanner(false);
    setRemoveType(e.currentTarget.value);
    removeContact(e.currentTarget.value, e.target.checked);
    const testontactType = checkForContactType();
    setContactTypeRemoveBanner(testontactType);
    if (e.currentTarget.value === "Licensed Producer") {
      if (e.target.checked) {
        setPersonalLineQuestion(true);
        setPersonalLineError(false);
        formMethod.setValue("personLineQuestion", "");
      } else {
        setPersonalLineQuestion(false);
        setPersonalLineError(false);
        formMethod.setValue("personLineQuestion", "");
      }
    }
  }

  const handleChange = (panel: string, type: string) =>
      (
        e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent,
        isExpanded: boolean
      ) => {
        if (editMode && expanded !== panel) {
          console.log(`You are about to lose unsaved changes from ${expanded}.`);
        }

        setEditMode(false);
        setExpanded(isExpanded ? panel : false);
        changeEditState('default');
      };

  useEffect(() => {
    formMethod.reset();
  }, [displayAddContactTypeModal]);

  function getActiveAgencyCodes() {
    const displayed = savedAgencyAddress.filter(agencyCode => agencyCode.is_agency_admin_authorized_to_update === '1');
    return displayed.length;
  }

  function getActiveContactTypes() {
    let validContactTypes: string = 'none';
    const result = contactTypes.filter((code) => orgContactElements.every((el: any) => el.name !== code.name));

    if (result.length === 0) {
      validContactTypes = 'all';
    }

    if (result.length === 1 && result[0].name === 'Principal') {
      validContactTypes = 'Principal';
    }
    return validContactTypes;
  }

  const getIsEditable = async () => {
    const adminPersonId = isAdmin ? decrypt(localStorage.getItem('personId')) : '0';

    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseIsEditable = API.graphql(
      graphqlOperation(
        queries.isEditable,
        {
          admin_person_id: adminPersonId,
          person_id: personId
        }
      ),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
      }
    ) as Promise<{ data: IsEditableQuery }>;

    return promiseIsEditable.then((res) => {
      if (res.data) {
        return res.data.isEditable;
      } else {
        handleGraphQLErrors(res);
      }
    })
    .then((res) => {
      if (res?.statusCode === 200) {
        return res.body;
      } else {
        throw new Error('Something went wrong - 11');
      }
    })
    .then((res) => {
      return res?.is_editable === 'YES';
    })
    .catch((err) => {
      if (err?.message) {
        console.error(err.message, err); 
      } else {
        handleGraphQLErrors(err);
      }
      window.location.href = "error";
    });
  }

  function getModal() {
    getIsEditable().then(response => {
      if (response) {
        const validContactTypes: string = getActiveContactTypes();
        const editMode = decrypt(localStorage.getItem('edit_state'));
        if (editMode !== 'add_contact_type' && editMode !== 'default') {
          setDisplayModal(true);
          localStorage.setItem('incoming', encrypt('add_contact_type'));
        } else {
          if (validContactTypes !== '') {
            if (validContactTypes === 'all') {
              setNoContactTypesRemain(true);
            }
            if (validContactTypes === 'Principal') {
              setOnlyPrincipalContactType(true);
            }
            if (validContactTypes === 'none') {
              getPrincipalHelpIcon();
              const hasAgencyCodes = getActiveAgencyCodes();
              if (hasAgencyCodes > 0) {
                setActiveAgencyCodes(true)
              }
              changeEditState('add_contact_type');
              setDisplayAddContactTypeModal(true);
            }
          }
        }
      }
      if (!response) {
        setPendingEdits(true);
      }
    });
  }

  const modalOnClickHandler = (event: any) => {
    event.stopPropagation();
  }

/*  const confirmModal = (event: any, typ: string, action: string) => {
    event.stopPropagation();

    const contactTypeCheckbox = document.getElementById(typ);
    if (action === 'cancel') {
      if (contactTypeCheckbox) {
        contactTypeCheckbox.click();
      }
      setConfirm(!confirm);
    }

    if (action === 'confirm') {
      removeContact(typ, false);
      const testontactType = checkForContactType();
      setContactTypeRemoveBanner(testontactType);
      setConfirm(!confirm);
    }

  }*/

  const onClickClose = (e: MouseEvent, action: string) => {
    setRemoveModal(false);
    setRemoveAgencyCodeModal(false);
  }

  const onClickSave = (type: string, reason: string = '') => {
    const data = {
      "contact_type_desc": type,
      "agency_cd": [],
      "updated_by": changedBy
    }
    sendData(data, reason);
  }

  const removeContact = (typ: string, checked: boolean) => {
    orgContactElements.findIndex((obj: any) => {
      if (obj.name === typ) {
        obj.checked = checked;
      }
    });
    filteredContactTypes.findIndex((obj: any) => {
      if (obj.name === typ) {
        obj.checked = checked;
      }
      if (checked) {
        setAddRemoveModalButtomMode(true);
      } else {
        setAddRemoveModalButtomMode(false)
      }
    });
    // orgContactElements[index].checked = false;
  }

  const modalOnLearnHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    setLearnMore(!learnMore);
  }

  const closeLearnMoreModal = () => {
    setLearnMore(!learnMore);
  }

  const learnMoreHandler = (event: any) => {
    event.stopPropagation();
    setLearnMore(!learnMore);
  }

  const closeAlertModal = (close: boolean) => {
      changeEditState('default');
      setDisplayModal(close);
      setNextModal(close);
  }

  const closeModal = (close: boolean) => {
    setDisplayModal(close);
    setNextModal(close);
    setShowToastMessage(true);
    setOnSaveChanges(true);
    handleEdit('cancel');
  }

  const scrollToId = (id: string) => {
    const panel = id.replace('panel-', 'id-') + '-accordion-title';
    const element = document.getElementById(panel);
    if (element) {
      const elementScroll = element?.getBoundingClientRect().top + window.scrollY - 150;
      window.scrollTo({
        top: elementScroll,
        behavior: "smooth"
      });
    }
  };

  const closeHandler = () => {
    let values = formMethod.getValues();
    let data: any[] = [];
      if (values) {
        if (values['Accounting Manager']) {
          data.push({
            "contact_type_desc": "Accounting Manager"
          });
        }
        if (values['Agency Admin']) {
          data.push({
            "contact_type_desc": "Agency Admin"
          });
        }
        if (values['Agency Manager']) {
          data.push({
            "contact_type_desc": "Agency Manager"
          });
        }
        if (values['Agency Staff']) {
          data.push({
            "contact_type_desc": "Agency Staff"
          });
        }
        if (values['Licensed Producer']) {
          data.push({
            "contact_type_desc": "Licensed Producer"
          });
        }
        if (values['Primary Claim Contact']) {
          data.push({
            "contact_type_desc": "Primary Claim Contact"
          });
        }
        if (values['Primary Mail Recipient']) {
          data.push({
            "contact_type_desc": "Primary Mail Recipient"
          });
        }
      }
      return data && data.length < 1 ? true : false;
  }

  const saveHandler = (event: any) => {
    let values: any = {};
    let data: any[] = [];
    formMethod.trigger().then(value => {
      if (value) {
        values = {...formMethod.getValues()};
        if (values['Accounting Manager']) {
          data.push({
            "contact_type_desc": "Accounting Manager",
            "id": 36
          });
        }
        if (values['Agency Admin']) {
          data.push({
            "contact_type_desc": "Agency Admin",
            "id": 3
          });
        }
        if (values['Agency Manager']) {
          data.push({
            "contact_type_desc": "Agency Manager",
            "id": 10
          });
        }
        if (values['Agency Staff']) {
          data.push({
            "contact_type_desc": "Agency Staff",
            "id": 7
          });
        }
        if (values['Licensed Producer']) {
          data.push({
            "contact_type_desc": "Licensed Producer",
            "id": 37
          });
        }
        if (values['Primary Claim Contact']) {
          data.push({
            "contact_type_desc": "Primary Claim Contact",
            "id": 13
          });
        }
        if (values['Primary Mail Recipient']) {
          data.push({
            "contact_type_desc": "Primary Mail Recipient",
            "id": 14
          });
        }
        /*if (values['Principal'] === undefined) {
          data.push({
            "contact_type_desc": "Principal",
            "id": 0
          });
        }*/
      }

      if (data && data.length < 1) {
        setContactTypeRemoveBanner(true);
      } else if (values['Licensed Producer'] && !values.personLineQuestion) {
        setPersonalLineError(true);
      } else {
        setNewContacts(data);
        setDisplayAddContactTypeModal(false);
        setNextModal(true);
        setContactTypeRemoveBanner(false);
        setPersonalLineError(false);
        setPersonalLineQuestion(false);
      }
    });
  }

  async function sendData(data: any, reason: string = '') {
    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseContactTypes = API.graphql(
      graphqlOperation(
        mutations.updatePendingPersonContactType,
        {
          body: data,
          person_id: personId
        }
      ),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
      }
    ) as Promise<{ data: UpdatePendingPersonContactTypeMutation }>;

    promiseContactTypes.then((res) => {
      if (res.data) {
        return res.data.updatePendingPersonContactType;
      } else {
        handleGraphQLErrors(res);
      }
    })
    .then((res) => {
      if (res?.statusCode === 200 || res?.statusCode === 400) {
        return res.body;
      } else {
        throw new Error('Something went wrong - 12');
      }
    })
    .then((res) => {
      // TODO if state being used add result to state
      if (res) {
        if (res.status === 'success') {
          if (removeModal && removeContactType && data?.contact_type_desc === 'Licensed Producer') {
            terminateProducerAppointments(reason);
          } else {
            setOnSaveChanges(true);
            setSnackbarTitle('Changes Saved')
            setSnackbarSubtitle('All your changes have been saved');
            setShowSnackbar(true);
            clearTimeout(delay);
            setShowSpinner(false);
            setShowModalSpinner(false);
            setSpinnerStatus('hide');
            setRemoveModal(false);
            setSelectAll(true);
            handleEdit('cancel');
          }
        }
        if (res.status === 'error') {
          clearTimeout(delay);
          setShowSpinner(false);
          setSpinnerStatus('hide');

          if (res.reason === 'Person contact types cannot be saved because the status is pending' ||
              res.reason === 'Person contact types cannot be saved because the event updated_by is not matching with ddb item updated_by') {
            setPendingEdits(true);
          } else if (res.reason === 'Person contact types cannot be saved because protected contact type check failed') {
            throw new Error(res.reason);
          } else {
            throw new Error('Something went wrong - 13');
          }
        }
      } else {
        throw new Error('Something went wrong - 14');
      }
    })
    .catch((err) => {
      clearTimeout(delay);
      setShowSpinner(false);
      setShowModalSpinner(false);
      setSpinnerStatus('hide');
      if (err?.message) {
        console.error(err.message, err); 
      } else {
        handleGraphQLErrors(err);
      }
      window.location.href = "error";
    });
  }

  const handleConfirmCancelClick = (event: any) => {
    setConfirm(false);
  }

  const handleConfirmContinueClick = (event: any) => {
    changeEditState('default');
    setConfirm(false);
    setDisplayAddContactTypeModal(false);
    setPersonalLineError(false);
    setPersonalLineQuestion(false);
  }

  const handleClickClose = () => {
    setConfirm(false);
    const isValid: boolean = closeHandler();
    if (isValid) {
      changeEditState('default');
      setContactTypeRemoveBanner(false);
      setPersonalLineError(false);
      setPersonalLineQuestion(false);
      setDisplayAddContactTypeModal(false);
    } else {
      setConfirm(true);
    }
  }

  const confirmEditModal = (event: any, action: string) => {
    event.stopPropagation();
    const incoming = decrypt(localStorage.getItem('incoming'));
    if (action === 'close') {
      if (typeof previouslyExpanded === 'string') {
        scrollToId(previouslyExpanded);
      }
      setExpanded(previouslyExpanded);
      setDisplayModal(!displayModal);
    }
    if (action === 'discard') {
      handleEdit('discard');
      if (incoming !== 'add_contact_type') {
        setExpanded(expanded);
        if (typeof expanded === 'string') {
          scrollToId(expanded);
        }
      } else {
        setExpanded(false);
      }
      setDisplayModal(false);
      if (incoming === 'add_contact_type'  && editState !== 'default') {
        localStorage.setItem('edit_state', encrypt('add_contact_type'));
        changeEditState('add_contact_type');
        setDisplayModal(false);
        getModal();
      }
    }
  }

  const handleEdit = (action: string) => {
    if (action === 'cancel') {
      changeEditState('default');
      setEditMode(false);
    }
    if (action === 'edit') {
      if (editState !== 'add_contact_type'  && editState !== 'default') {
        setDisplayModal(true);
        localStorage.setItem('incoming', encrypt('add_contact_type'));
      } else {
        changeEditState('add_contact_type');
      }
    }
    if (action === 'discard') {
      const inComing = decrypt(localStorage.getItem('incoming'));
      if (inComing !== undefined) {
        changeEditState(inComing);
      } else {
        changeEditState('default');
      }
    }
  }

  useEffect(() => {
    if (!saveError && showToastMessage && !displayApplicationGeneratedModal && !licensedProducerOnly && !suppressToastMessage) {
      setSnackbarTitle('Changes Saved')
      setSnackbarSubtitle('All your changes have been saved');
      setShowSnackbar(true);
      setShowToastMessage(false);
    }
  }, [showToastMessage, displayApplicationGeneratedModal, licensedProducerOnly, suppressToastMessage, saveError]);

  useEffect(() => {
    if (displayModal || learnMore || displayAddContactTypeModal || nextModal || displayApplicationGeneratedModal) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [displayModal, displayAddContactTypeModal, learnMore, nextModal, displayApplicationGeneratedModal]);

  useEffect(() => {
    if (agencyCodeMap) {
      const sortedAgencyAddress = agencyCodeMap.sort(function (a: any, b: any) {
        if (Number(a.agency_code) < Number(b.agency_code)) {
          return -1;
        }
        if (Number(a.agency_code) > Number(b.agency_code)) {
          return 1;
        }
        return 0;
      });

      setSavedAgencyAddress(sortedAgencyAddress);

      const r = agencyCodeMap;
      let elements: any = [];
      let uniqueElements: any = [];
      let originalContactTypes: any = [];
      let newContactTypes: any = [];
      let a = r;

      for (let i in a) {
          for (let j of a[i].contact_type.split(',')) {
            let g: any = {};
            if (uniqueElements.indexOf(j) === -1) {
              g['address_line1'] = a[i].address_line1;
              g['address_line2'] = a[i].address_line2;
              g['agency_code'] = a[i].agency_code;
              g['agency_code_name'] = a[i].agency_code_name;
              g['agency_id'] = a[i].agency_id;
              g['city'] = a[i].city;
              g['contact_type'] = [j];
              g['state_code'] = a[i].state_code;
              g['zip'] = a[i].zip;
              newContactTypes.push(g);
            }
          }
      }
      setContactTypesData(newContactTypes);

      for (let i in r) {

        if (r[i].contact_type_id !== "") {
          for (let j of r[i].contact_type_id.split(',')) {
            let g: any = {};
            if (uniqueElements.indexOf(j) === -1) {
              g['agency_code'] = r[i].agency_code;
              g['agency_code_name'] = r[i].agency_code_name;
              g['agency_id'] = r[i].agency_id;
              g['contact_type'] = [j];
              g['address_line1'] = r[i].address_line1;
              g['address_line2'] = r[i].address_line2;
              g['city'] = r[i].city;
              g['state_cede'] = r[i].state_code;
              g['zip'] = r[i].zip;
              uniqueElements.push(j);
              elements.push(g);
            }
          }
        }
      }

      elements.map((el: any, idx: any) => {
        if (null !== el.contact_type_id) {
          ContactData.contacttype.find((data) => {
            el["contact_type"].forEach((ele: string) => {
              if (data.contacttype.toString() === ele) {
                const orgContactType = {
                  name: data?.description,
                  checked: true,
                  agency: el
                };
                data.checked = true;
                el["contact_type"].push(data?.description);
                originalContactTypes.push(orgContactType);
              }
            })
          });
        }
      });

      const filteredElements = elements.filter((item: any) => item.contact_type[1] !== undefined);
      const sortedElements = filteredElements.sort((a: any, b: any) => {
        if (a.contact_type[1] && b.contact_type[1]) {
          if (a.contact_type[1].toLowerCase() < b.contact_type[1].toLowerCase()) {
            return -1;
          } else if (a.contact_type[1].toLowerCase() > b.contact_type[1].toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        }
      });

      setOrgContactElements(originalContactTypes.sort((a: any, b: any) => a.name > b.name ? 1 : -1));
      setContactElements(sortedElements);
    }
  }, [props?.agencyCodeMap, agencyCodeMap]);

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modaladdcontacttypes') as HTMLElement;
      if (modal) {
        const element = modal.querySelector('input') as HTMLElement;
        if (element) {
          (element as HTMLElement).focus();
        }
      }
    }

    function focusDefaultLearnMore() {
      const modal = document.querySelector('#modaladdcontactlearnmore') as HTMLElement;
      if (modal) {
        const element = modal.querySelector('button') as HTMLElement;
        if (element) {
          (element as HTMLElement).focus();
        }
      }
    }

    function focusModalAddContactTypes(e: any) {
      const modal = document.querySelector('#modaladdcontacttypes') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          const element = modal.querySelector('button') as HTMLElement;
          if (element) {
            element.focus();
          }
        }
      }
    }

    function focusModalAddContactTypesLearnMore(e: any) {
      const modal = document.querySelector('#modaladdcontactlearnmore') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          const element = modal.querySelector('button') as HTMLElement;
          if (element) {
            element.focus();
          }
        }
      }
    }

    if (displayAddContactTypeModal) {
      focusDefault();
      document.addEventListener('focusin', focusModalAddContactTypes);
      document.addEventListener('focusout', focusModalAddContactTypes);
    }

    if (displayAddContactTypeModal && learnMore) {
      focusDefaultLearnMore();
      document.addEventListener('focusin', focusModalAddContactTypesLearnMore);
      document.addEventListener('focusout', focusModalAddContactTypesLearnMore);
    }

    return () => {
      document.removeEventListener('focusin', focusModalAddContactTypes);
      document.removeEventListener('focusout', focusModalAddContactTypes);
      document.removeEventListener('focusin', focusModalAddContactTypesLearnMore);
      document.removeEventListener('focusout', focusModalAddContactTypesLearnMore);
    }
  }, [displayAddContactTypeModal, learnMore]);

  useEffect(() =>  {
    if (displayAddContactTypeModal && learnMore) {
      const element = document.querySelector('#modalbody');
      element?.setAttribute('tabindex', '0');
    }
  }, [displayAddContactTypeModal, learnMore]);



  function handleClickCancelAlertModalLeave(event: React.MouseEvent) {
    history.push({
      pathname: `/agency-contacts`
    });
  }

  function handleClickCancelAlertModalStay(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleClickLearnMoreModalClose(event: React.MouseEvent) {
    setDisplayLearnMoreModal(!displayLearnMoreModal);
  }

  function handleClickCloseContactAgencyOperations(event: React.MouseEvent) {
    setDisplayContactAgencyOperationsModal(!displayContactAgencyOperationsModal);
    changeEditState('default');
  }

  function handleClickCloseOnlyPrincipalRemains(event: React.MouseEvent) {
    changeEditState('default');
    setOnlyPrincipalContactType(!onlyPrincipalContactType);
  }

  function handleClickNoContactTypesRemain(event: React.MouseEvent) {
    setNoContactTypesRemain(!noContactTypesRemain);
  }

  function handleDisplayLastContactTypeModal(event: React.MouseEvent) {
    setDisplayLastContactTypeModal(!displayLastContactTypeModal);
  }

  const handleTrashClick = async (type: string) => {
    if (!isEmployee) {
      let ct: string = '';
      contactTypes.find((item: any) => {
        if (item.name === type) {
          ct = item.id;
        }
      })
      if (orgContactElements.length === 1) {
        setDisplayLastContactTypeModal(true);
      } else {
        setShowModalSpinner(true);
        const adminPersonId =  decrypt(localStorage.getItem('personId'));

        const isAuthenticated: boolean = await checkAuthStatus();
        if (!isAuthenticated) {
          return;
        }
        const promiseIsEditable = API.graphql(
          graphqlOperation(
            queries.isremovable,
            {
              admin_person_id: adminPersonId,
              contact_type: ct,
              person_id: personId
            }
          ),
          {
            Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
          }
        ) as Promise<{ data: IsremovableQuery }>;

        promiseIsEditable.then((res) => {
          if (res.data) {
            return res.data.isremovable;
          } else {
            handleGraphQLErrors(res);
          }
        })
        .then((res) => {
          if (res?.statusCode === 200 || res?.statusCode === 400) {
            return res.body;
          } else {
            throw new Error('Something went wrong - 15');
          }
        })
        .then((res) => {
          if (res) {
            let data: any = {};
            data.title = res.reason;
            data.type = res.contact_type;
            data.action = res.status;
            if (res.agency_codes && res.agency_codes.length > 0) {
              data.agency_codes = res.agency_codes;
            }
            setShowModalSpinner(false);
            setRemoveContactType(data);
            setRemoveModal(true);
          } else {
            throw new Error('Something went wrong - 16');
          }
        })
        .catch((err) => {
          if (err?.message) {
            console.error(err.message, err); 
          } else {
            handleGraphQLErrors(err);
          }
          window.location.href = "error";
        });
      }
    }
  }

  const selectAllDeselectAllButtonState = (ct: string) => {
    let isChecked = true;
    const element = document.getElementsByName(ct);
    if (element !== null) {
      element.forEach((item: any) => {
        if (!item.checked) {
          isChecked = false;
        }
      })
    }
    return isChecked;
  }

  const checkElementIfHidden = (agencyCodeId: string) => {
    let isHidden = false;
    const agencyCodeElement = document.getElementById(agencyCodeId) as HTMLElement;
    const parentElement = agencyCodeElement.parentElement as HTMLElement;
    const parentOfParent = parentElement.parentElement as HTMLElement;

    if (parentOfParent.classList.contains('hidden')) {
      isHidden = true;
    }
    return isHidden;
  }

  function handleClickSelectAll(event: React.MouseEvent, action: string, contactType: string) {
    const element = document.getElementsByName(contactType);
    if (element !== null) {
      element.forEach((item: any, i: number) => {
        const isHidden = checkElementIfHidden(contactType + item.value);
        if (action === 'select') {
          if (!isHidden && !item.checked) {
            item.click();
          }
        } else {
          if (!isHidden && item.checked) {
            item.click();
          }
        }
      })
    }
    setSelectAll(!selectAll);
  }

  function onSnackbarClose() {
    setShowSnackbar(false);
  }

  function handleMouseHover(event: React.MouseEvent<HTMLElement> | any) {
    event.preventDefault();
    if (referenceElement === null) {
      setReferenceElement(event.currentTarget);
    }
  }

  const accordion = () => {
    const accordions = orgContactElements.map((el: any, i) => {
      if (el?.name) {
        return (
          <StyledAccordions key={i}>
            <SAAccordion
              id={`id-${i}`}
              expanded={expanded === `panel-${i}`}
              title={(el?.name !== null && el?.name) ? el?.name : ""}
              textTransform="uppercase"
              variant="standard"
              onChange={handleChange(`panel-${i}`, el?.name)}
              buttons={(expanded !== `panel-${i}`) || (expanded === `panel-${i}` && !editMode) ? getButtons(`panel-${i}`, el?.name) : null}
            >
              {!editMode &&
              (
                el?.name ===  "Agency Admin")  && (
                <AgencyLocationPanel>
                  <ContactTypeAccordingViewText type="heading-4" text="Agency Codes" />
                  <AgencyCodeContent>
                    {getAgencyCodes(el?.name)}
                  </AgencyCodeContent>
                </AgencyLocationPanel>
              )}
              {!editMode &&
              (
                el?.name ===  "Agency Manager")  && (
                <AgencyLocationPanel>
                  <ContactTypeAccordingViewText type="heading-4" text="Agency Codes" />
                  <AgencyCodeContent>
                    {getAgencyCodes('Agency/Office Manager')}
                  </AgencyCodeContent>
                </AgencyLocationPanel>
              )}
              {!editMode &&
              (
                el?.name ===  "Agency Staff")  && (
                <AgencyLocationPanel>
                  <ContactTypeAccordingViewText type="heading-4" text="Agency Codes" />
                  <AgencyCodeContent>
                    {getAgencyCodes(el?.name)}
                  </AgencyCodeContent>
                </AgencyLocationPanel>
              )}
              {!editMode && (
                el?.name ===  "Accounting Manager")  && (
                <AgencyLocationPanel>
                  <ContactTypeAccordingViewText type="heading-4" text="Agency Codes" />
                  <AgencyCodeContent>
                    {getAgencyCodes(el?.name)}
                  </AgencyCodeContent>
                </AgencyLocationPanel>
              )}
              {!editMode && (
                el?.name ===  "Primary Claim Contact")  && (
                <AgencyLocationPanel>
                  <ContactTypeAccordingViewText type="heading-4" text="Agency Codes" />
                  <AgencyCodeContent>
                    {getAgencyCodes(el?.name)}
                  </AgencyCodeContent>
                </AgencyLocationPanel>
              )}
              {!editMode &&
              (
                el?.name ===  "Primary Mail Recipient")  && (
                <AgencyLocationPanel>
                  <ContactTypeAccordingViewText type="heading-4" text="Agency Codes" />
                  <AgencyCodeContent>
                    {getAgencyCodes(el?.name)}
                  </AgencyCodeContent>
                </AgencyLocationPanel>
              )}
              {!editMode && (
                el?.name ===  "Principal")  && (
                <AgencyLocationPanel>
                  <ContactTypeAccordingViewText type="heading-4" text="Agency Codes" />
                  <AgencyCodeContent>
                    {getAgencyCodes(el?.name)}
                  </AgencyCodeContent>
                </AgencyLocationPanel>
              )}
              {!editMode && (
                el?.name ===  "Licensed Producer")  && (
                <AgencyLocationPanel>
                  <ContactTypeAccordingViewText type="heading-4" text="Agency Codes" />
                  <AgencyCodeContent>
                    {getAgencyCodes(el?.name)}
                  </AgencyCodeContent>
                </AgencyLocationPanel>
              )}
              {(editMode) && <>
              {accordionContent(el?.name, i)}
              </>
                }
            </SAAccordion>
            {!crossHierarchy &&
              <StyledTrashIcon>
                {el?.name !== 'Principal' && (
                  <>
                    <StyledTrashButton
                    key={'button' + i.toString()}
                    label="Remove"
                    onClick={() => handleTrashClick(el?.name)}
                    variant="link-small"
                    disabled={isEmployee}
                    >
                      <SAIcon
                        key={'icon' + i.toString()}
                        colorVariant="#EA959B"
                        icon={SAIcons.trash}
                        size="24px"
                        disabled={isEmployee}
                      />
                    </StyledTrashButton>
                  </>
                )}
              </StyledTrashIcon>
            }
          </StyledAccordions>
        );
      } else {
        return false;
      }
    });
    return accordions;
  };

  const getAgencyCodes = (typ: string) => {
    return savedAgencyAddress?.map((item: any, i: number) => {
      if (item.contact_type?.split(',').length) {
        return item.contact_type?.split(',').map((items: any) => {
          if (items.toString() === typ) {
            const producer = item['agency_code'] + ' - ';
            return (
              <AgencyLocationWrapper key={i}>
                <AgencyLocations type="standard"
                                 text={producer + item['agency_code_name'] + ', ' + item.address_line1 + ', ' + item.city + ', ' + item.state_code + ' ' + item.zip.substr(0, 5)}/>
              </AgencyLocationWrapper>
            )
          }
        })
      }
    })
  }

  const getAddContactTypes = () => {
    const displayed = getDisplayedContactTypes();
    return (
      displayed.map((el, i) => (
        <div key={i}>
          {!el?.checked && (
            <StyledAdContactTypeCheckbox>
              <SACheckbox
                {...register(`${el?.name}`)}
                id={el?.name}
                // checked={el?.['checked']}
                onChange={e => getMe(e)}
                value={el?.name}
                label={el?.name}
                disabled={el?.name === 'Principal'}
              />
            </StyledAdContactTypeCheckbox>
          )}
        </div>
      ))
    )

  }

  const getExitingContactTypes = () => {
    const displayed = getDisplayedContactTypes();
    let existingContactTypes: string = '';

    displayed.map((el, i) => {
      if (el?.checked) {
        existingContactTypes = existingContactTypes + el?.name + ', ';
      }
    });

    existingContactTypes = existingContactTypes.replace(/,\s*$/, '');

    return (
      <SAText
        type="standard"
        text={existingContactTypes}
      />
    )
  }

  const getEditAgencyCodes = (typ: string) => {
    const agencyCodeMapArrayChecked = savedAgencyAddress?.filter((item: any) => {
      if (item.contact_type.indexOf(typ) > -1) {
        return true;
      }
    });

    const agencyCodeMapArrayUnChecked = savedAgencyAddress?.filter((item: any) => {
      if (item.contact_type.indexOf(typ) === -1 && item.is_agency_admin_authorized_to_update !== '0') {
        return true;
      }
    });

    const filteredAgencyCodeMap = [...agencyCodeMapArrayChecked, ...agencyCodeMapArrayUnChecked];

      return filteredAgencyCodeMap?.map((item: any, i: number) => {
        const ct = typ === 'Agency/Office Manager' ? 'Agency Manager' : typ;
        return (
          <Controller
            key={ct + item.agency_code + i.toString()}
            control={control}
            name={ct}
            render={({ field }) => (
              <SACheckbox
                id={ct + item.agency_code}
                name={ct}
                disabled={item.is_agency_admin_authorized_to_update === '0'}
                value={item.agency_code}
                defaultChecked={item.contact_type.includes(typ)}
                label={item.agency_code + ' - ' + item.agency_code_name + ', ' + item.address_line1 + ', ' + item.city + ', ' + item.state_code + ' ' + item.zip.substr(0, 5)}
                onClick={(event: any) => {
                  const gen_details = JSON.parse(decrypt(localStorage.getItem('general_details')));
                  const primaryAgencyCode = gen_details?.primary_agency_code ? gen_details.primary_agency_code : '';
                  let primaryAgencyCodeNationalNumber = '';
                  let currentAgencyCodeNationalNumber = '';
                  agencyCodeMap.forEach((agency) => {
                    if (primaryAgencyCode === agency.agency_code) {
                      primaryAgencyCodeNationalNumber = agency?.national_number;
                    }
                    if (event.target.value === agency.agency_code) {
                      currentAgencyCodeNationalNumber = agency?.national_number;
                    }
                  });
                  if (primaryAgencyCodeNationalNumber !== currentAgencyCodeNationalNumber) {
                    props.displayCrossHierarchyModal(true);
                    event.target.checked = false;
                    simpleGTMDataLayer({
                      event: 'crossHierarchyModalEvent',
                      event_action: 'Edit Contact Type',
                      event_category: window.location.pathname,
                      event_label: 'Profile Page'
                    });
                  }                  
                }}
                onChange={(event: any) => {
                  field.onChange(event.target.checked);
                  handleClick(event,'validate', i.toString(), ct);
                }}
              />
            )}
          />
        )
      })
  }

  const accordionContent = (contactType: string, i: number) => {
    return (
      <AccordionContent>
        <SearchContainer>
          <Search>
            <SASearch
              fullWidth
              id={`contacttype_${contactType}_searchbar`}
              onSearch={(search: Array<string> | string) => handleSearch(search, contactType)}
              placeholder="Search by agency code, agency name, city, state, or zip"
              // values={searchTerms}
              variant="search-terms"
            />
          </Search>
          {!selectAll ? (
            <SelectAllButton
              label="Deselect All"
              onClick={(event: React.MouseEvent) => handleClickSelectAll(event, 'deselect', contactType)}
              textTransform="uppercase"
              variant="link-medium"
            />
          ) : (
            <SelectAllButton
              label="Select All"
              onClick={(event: React.MouseEvent) => handleClickSelectAll(event, 'select', contactType)}
              textTransform="uppercase"
              variant="link-medium"
            />
          )}
        </SearchContainer>
        <AgencyCodesContainer isCheckboxesValid={isCheckboxesValid}>
          <span className="heading">Agency Codes</span>
          <AgencyCodesCheckboxes>
            <AgencyLocationWrapper>
              {getEditAgencyCodes(contactType === 'Agency Manager' ? 'Agency/Office Manager' : contactType)}
            </AgencyLocationWrapper>
          </AgencyCodesCheckboxes>
        </AgencyCodesContainer>
        {!isCheckboxesValid && (<StyledError>At least one Agency Code is required</StyledError>)}
        <div className="buttonsAct">
          <SAButton
            label="Cancel"
            onClick={(e) => handleClick(e, "Cancel", "panel" + { i }, contactType)}
            color="text"
            variant="link-medium"
          />
          <SAButton
            label="Save Changes"
            disabled={!isCheckboxesValid || isEmployee}
            onClick={(e) => handleClick(e, "SaveChanges", "panel" + { i }, contactType)}
            variant="primary-medium"
          />
        </div>
      </AccordionContent>
    );
  };

  function handleSearch(search: Array<string> | string, contactType: string) {
       if (typeof search !== 'string') {
        const elements: HTMLElement[] = [];
         agencyCodeMap.forEach((agencyCode: AgencyCode) => {
           const element: HTMLElement | null = document.querySelector(`label[for="${contactType}${agencyCode.agency_code}"]`)
           if (element !== null) {
             elements.push(element);
           }
         });

         const filteredElements = elements.filter((element: HTMLElement) => {

          let containsAllSearchTerms: boolean = true;
          search.forEach((searchTerm) => {
            let containsSearchTerm: boolean = false;

            if (element.innerText.toLowerCase().includes(searchTerm.toLowerCase())) {
              containsSearchTerm = true;
            }
            if (!containsSearchTerm) {
              containsAllSearchTerms = false;
            }
          });

         return containsAllSearchTerms;
        });

        elements.forEach((element: HTMLElement, index: number) => {
          const parentElement = element.parentElement as HTMLElement;
          if (filteredElements.includes(element)) {
            parentElement.classList.remove('hidden');
          } else {
            parentElement.classList.add('hidden');
          }
        });
    }
  }

  async function terminateProducerAppointments(reason: string) {
    const payload: AppGenPayloadRemove = {
      agency_admin_person_id: changedBy,
      person_id: personId,
      person_first_name: firstName,
      person_last_name: lastName,
      reason: reason
    };

    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseTerminateProducerAppointments = API.graphql(
      graphqlOperation(
        mutations.terminateProducerAppointments,
        {
          body: payload
        }
      ),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
      }
    ) as Promise<{ data: TerminateProducerAppointmentsMutation }>;
    
    promiseTerminateProducerAppointments.then((res) => {
      if (res.data) {
        return res.data.terminateProducerAppointments;
      } else {
        handleGraphQLErrors(res);
      }
    })
    .then((res) => {
      if (res?.statusCode === 200) {
        return res.body;
      } else {
        return null;
      }
    }).then((res) => {
      if (res) {
        if (res.message === 'Onboarding request for licensed producer mail has been delivered successfully') {
          console.log('Email to request termination of producer appointments has been sent');
          setOnSaveChanges(true);
          setSnackbarTitle('Changes Saved')
          setSnackbarSubtitle('All your changes have been saved');
          setShowSnackbar(true);
          clearTimeout(delay);
          setShowSpinner(false);
          setShowModalSpinner(false);
          setSpinnerStatus('hide');
          setRemoveModal(false);
          setSelectAll(true);
          handleEdit('cancel');
        } else {
          throw new Error('Something went wrong - 17');  
        }
      } else {
        throw new Error('Something went wrong - 18');
      }
    })
    .catch((err) => {
      if (err?.message) {
        console.error(err.message, err); 
      } else {
        handleGraphQLErrors(err);
      }
      window.location.href = "error";
    });
  }

  return (
    <Container>
      <SASnackbar
        open={showSnackbar}
        variant="snackbar-success"
        position="top-end"
        autoHideDuration={5000}
        title={snackbarTitle}
        subtitle={snackbarSubtitle}
        offset={[-25, 170]}
        onClose={onSnackbarClose}
      />
      {displayAddContactTypeModal && (
        <ContactTypeSectionWrapper>
          <Modal id="modaladdcontacttypes" onClickHandler={(event: React.MouseEvent) => modalOnClickHandler(event)} display={learnMore || confirm ? 'none' : 'flex'}>
            <AddContactTypeContainer variant="minimal">
              <div className="contents">
                <ContactTypes variant="simple" title="Add Contact Type(s)" actionLabel="LEARN MORE" actionLabelClickHandler={learnMoreHandler}>
                  {getAddContactTypes()}
                </ContactTypes>
                {personalLineQuestion && (
                  <PersonalLineQuestion>
                    <SAText
                      type="standard"
                      weight="bold"
                      text="Is this licensed producer needed only for Farm business?"
                    />
                    <Controller
                      control={control}
                      defaultValue={""}
                      name="personLineQuestion"
                      render={({ field: { onChange, value } }) => (
                        <SARadioGroup
                          value={value}
                          onChange={(e) => {
                            const el = document.querySelector("[id='Licensed Producer']") as HTMLInputElement;
                            if (e.currentTarget.value === "no" && el && el.checked) {
                              el.click();
                              formMethod.setValue("Licensed Producer", false);
                            }
                            setPersonalLineError(false);
                            onChange(e.currentTarget.value);
                          }}
                          id="personlinequestion"
                          variant="standard"
                          direction="horizontal">
                          <SARadio value="yes" label="Yes" variant="standard" />
                          <SARadio value="no" label="No" variant="standard" />
                        </SARadioGroup>
                      )}
                    />
                    {personalLineError && (
                      <ErrorMessage>
                        <SAText type="small" colorVariant="alert" text="Answer is required." />
                      </ErrorMessage>
                    )}
                    <PersonalLineAlert severity="info">
                      <strong>Note*</strong> We are only accepting new business for Farm on this platform and it is
                       unnecessary to onboard a licensed producer solely for personal or commercial lines.
                    </PersonalLineAlert>
                  </PersonalLineQuestion>
                )}
                <ExistingContactType>
                  <SAText size="200" text="Existing Contact Types" weight="bold" />
                  <ExistingContactTypes>
                    {getExitingContactTypes()}
                  </ExistingContactTypes>
                </ExistingContactType>
                {contactTypeRemoveBanner && (
                  <CardValidation>
                    <SAText type="paragraph" text="At least one contact type is required." />
                  </CardValidation>
                )}
              </div>
              <div className="adjustBan">
                <AddContactTypeFooterButtons>
                  <SAButton
                    color="text"
                    label="Cancel"
                    onClick={handleClickClose}
                    variant="link-large"
                  />
                  {!showSpinner &&
                    <SAButton fullWidthUnder={mobileWidth} label="Next" type="submit" variant="primary-large" textTransform="uppercase" onClick={saveHandler} />
                  }
                  {showSpinner &&
                    <DisabledContinueButton endIcon={spinner} disabled fullWidthUnder={mobileWidth} label="Next" type="submit" variant="primary-medium" textTransform="uppercase" />
                  }
                </AddContactTypeFooterButtons>
              </div>
            </AddContactTypeContainer>
          </Modal>
          {learnMore &&
            <Modal id="modaladdcontactlearnmore" variant="simple" onClickHandler={(event: React.MouseEvent) => modalOnLearnHandler(event)}>
              <LearnMoreContainer variant="minimal" removePadding>
                <LearnMoreHeader>
                  <SAButton
                    variant="link-medium"
                    fullWidth={false}
                    label="Continue Editing Contact Type"
                    startIcon={<SAIcon icon={SAIcons.chevronLeftBold} size={SAIconSize.small} colorVariant="text" />}
                    textTransform="uppercase"
                    color="text"
                    onClick={handleClickLearnMoreModalClose}
                  />
                </LearnMoreHeader>
                <LearnMoreDiv id="modalbody">
                  <LearnMoreContentContainer>
                    {data.map((el, i) => (
                      <div key={i}>
                        <div>
                          <StyledLearnMoreTitleText type="heading-4" text={el?.description} />
                          <div className="learnCard">
                            <StyledLearnMoreText type="standard" text={el?.definition} />
                          </div>
                        </div>
                        <LearnMoreLineSpacing>
                          <hr />
                        </LearnMoreLineSpacing>
                      </div>
                    ))}
                  </LearnMoreContentContainer>
                </LearnMoreDiv>
              </LearnMoreContainer>
            </Modal>
          }
        </ContactTypeSectionWrapper>
      )}
      <Header>
        <StyledHeader type="heading-3" text=" Contact Type" />
        {!crossHierarchy &&
          <StyledSAAddContactTypeButton
            label="ADD CONTACT TYPE"
            variant="primary-lightweight-small"
            onClick={getModal}
            startIcon={<SAIcon colorVariant="rgba(65, 85, 32, 1)" icon={SAIcons.plus} size="20px"/>}
          />
        }
      </Header>
      {crossHierarchy &&
        <CrossHierarchyText>
          Changes in this section are disabled.  Contact Agency Operations for assistance at <a href="mailto:Agency.Operations@stateauto.com">Agency.Operations@stateauto.com</a>
        </CrossHierarchyText>
      }
      <ContactTypeSectionWrapper>
        <StyledSACard variant="minimal">
          {accordion()}
        </StyledSACard>
      </ContactTypeSectionWrapper>
      {confirm &&
        <Modal onClickHandler={(event: React.MouseEvent) => modalOnClickHandler(event)}>
          <ConfirmContactTypeChanges variant="simple">
            <ConfirmContactTypeChangesModal>
              <ConfirmContactTypeChangesModalTitle>
                <SAText type="heading-2" text="Changes will not be saved" />
                <SAIcon className="closemodal" icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" onClick={handleConfirmCancelClick} />
              </ConfirmContactTypeChangesModalTitle>
            </ConfirmContactTypeChangesModal>
            <ConfirmContactTypeChangesModalBody>
              <ConfirmContactTypeChangesModalText type="standard" text="Are you sure you want to leave this section? Changes will not be saved." />
            </ConfirmContactTypeChangesModalBody>
            <ConfirmContactTypeChangesModalFooter>
              <SAButton
                label="Continue Editing"
                onClick={(e) => handleConfirmCancelClick(e)}
                variant="link-large"
                color="text"
              />
              <SAButton
                fullWidthUnder={mobileWidth}
                label="Cancel Changes"
                type="submit"
                variant="primary-large"
                textTransform="uppercase"
                onClick={handleConfirmContinueClick}
              />
            </ConfirmContactTypeChangesModalFooter>
          </ConfirmContactTypeChanges>
        </Modal>
      }
      {nextModal &&
        <ModalAddNewContactTypeAgencyCodes
          closeAlertModal={closeAlertModal}
          closeModal={closeModal}
          appGenPayload={(payload: AppGenPayloadAdd) => setPayload(payload)}
          showAppGenModal={(value: boolean) => setDisplayApplicationGeneratedModal(value)}
          licensedProducerOnly={(value: boolean) => setLicensedProducerOnly(value)}
          suppressToastMessage={(value: boolean) => setSuppressToastMessage(value)}
          saveError={(value: boolean) => setSaveError(value)}
          newContactTypes={newContacts}
          contactTypes={contactTypes}
          showAlreadyRequestedLP={(data: {adminName: string, requestedOn: string})=> setDisplayLPModalAlert(data)}
        />
      }
      <SAPopover
        onClose={(e: MouseEvent, index: number | null) => closePopover(e, index)}
        offset={offset}
        placement={position}
        referenceElement={referenceElement}
        variant={mobile ? 'popover-minimal-nopadding' : 'popover-menu-no-click'}
      >
        <Popover>
          <HelpContainer>
            <HelpIcon>
              <SAIcon icon={SAIcons.information} size="20px" colorVariant="hsla(220, 38%, 97%, 1)" />
            </HelpIcon>
            <HelpText>
              {popover === 'Principal' &&
                <HelpTextContent>
                  <HelpTextHeader>Need to edit or remove Principal?</HelpTextHeader>
                  <div>In order to make changes to this contact type, please contact Agency Operations at Agency.Operations@stateauto.com.</div>
                </HelpTextContent>
              }
            </HelpText>
          </HelpContainer>
        </Popover>
      </SAPopover>
      {displayModal && (
        <ConfirmEditModal confirmModal={confirmEditModal} />
      )}
      {removeModal && removeContactType &&
        <ModalContactTypeRemove
          title = {removeContactType?.title}
          type = {removeContactType.type}
          action = {removeContactType.action}
          agency_codes = {removeContactType.agency_codes}
          onClickClose = {onClickClose}
          onClickSave = {(e: Event, type: string, reason: string) => {
            setShowModalSpinner(true);
            setRemoveModal(false);
            if (type === 'Licensed Producer') {
              onClickSave(removeContactType.type, reason);
            } else {
              onClickSave(removeContactType.type);
            }
          }}
        />
      }
      {removeAgencyCodeModal && removeContactType &&
        <ModalContactTypeRemove
          title = {removeContactType?.title}
          type = {removeContactType.type}
          action = {removeContactType.action}
          agency_codes = {removeContactType.agency_codes}
          onClickClose = {onClickClose}
          onClickSave = {(e: Event, type: string, reason: string) => {
            setShowModalSpinner(true);
            setRemoveAgencyCodeModal(false);
            onClickSave(removeContactType.type);
          }}
        />
      }
      {displayCancelAlert &&
        <ModalCancelAlert onClickLeave={handleClickCancelAlertModalLeave} onClickStay={handleClickCancelAlertModalStay} />
      }
      {onlyPrincipalContactType &&
        <ModalAddContactTypeAlerts
          title="Contact Type Cannot be Added"
          text="All contact types have already been assigned."
          note={true}
          onClickClose={handleClickCloseOnlyPrincipalRemains}
        />
      }
      {noContactTypesRemain &&
        <ModalAddContactTypeAlerts
          title="Contact Type Cannot be Added"
          text="All contact types have already been assigned."
          note={false}
          onClickClose={handleClickNoContactTypesRemain}
        />
      }
      {displayContactAgencyOperationsModal &&
        <ModalContactAgencyOperations
          title="Contact Agency Operations"
          text="Contact Agency Operations to add a Principal."
          onClickClose={handleClickCloseContactAgencyOperations}
        />
      }
      {displayLastContactTypeModal &&
        <ModalLastContactType
          title="Contact Type Cannot be Removed"
          text="At least one contact type must be assigned."
          onClickClose={handleDisplayLastContactTypeModal}
        />
      }
      {displayApplicationGeneratedModal &&
        <ModalApplicationGenerated
          workflow="profile"
          type="add"
          onClickCancel={handleClickApplicationGeneratedModalCancel}
          onClickClose={handleClickApplicationGeneratedModalClose}
          displaySpinner={showAppGenModalSpinner}
          onClickSendEmail={handleClickSendEmail}
          onEmailSent={handleEmailSent}
          payload={payload}
          showLPAlreadyRequested={handleLicenseProducerApplication}
        />
      }
      {displayLPModalAlert && 
        <ModalLicenceProducerApplication
          onClickClose={handleClickLPAlertModalCancel}
          contactName={firstName + " " + lastName}
          adminName={displayLPModalAlert.adminName}
          requestedOn={displayLPModalAlert.requestedOn}
        />
      }
      {pendingEdits &&
          <ModalPendingEdits isAdmin={isAdmin} onClickClose={handlePendingEditsClose} />
      }
      {showModalSpinner && <ModalSpinner />}
    </Container>
  );
}
