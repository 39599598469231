import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { NewContact, NewContactContactType, AppContextProps, PersonInfo, Constants } from '../../interfaces/interfaces';
import { SAHeader, SAButton, SAIcon, SAIcons, SAIconSize, SAStepper, SAStep, SAUXTheme, SAUX360Theme, ThemeColorsConfig, SAText } from '@saux/design-system-react';
import { PathMap } from '../../interfaces/interfaces';
import styled from 'styled-components';
import ConfirmEditModal from '../ConfirmEditModal';
import { encrypt, decrypt } from '../../utils/crypto';
import { constants } from '../../utils/constants';
import configuration from '../../env_config';
import whatInput from 'what-input';

const mobileWidth = 600;

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
  pathname?: string;
  isAdmin?: boolean;
  isEmployee?: boolean;
  isAgencyOpsInternalUser?: boolean;
  portal?: {
    isDisable: boolean;
    message: string;
  }
};

const DockedHeader = styled(SAHeader)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  button {
    cursor: pointer;
  }
`;

const DisabledPortalBanner = styled.div.attrs(({constants}: StyleProps) => ({
  constants
}))`
  ${({ theme, constants }: StyleProps) => {
    return `
      display: flex;
      align-items: center;
      flex-direction: row;
      position: fixed;
      top: ${constants?.headerHeight}px;
      left: 0;
      width: 100%;
      z-index: 2;
      background-color: #b30000;
      padding: 0 45px;
      height: ${constants?.disableBannerHeight}px;

      @media only screen and (max-width: ${mobileWidth}px) {
        top: ${constants?.headerHeightMobile}px;
        padding: 0 ${theme?.size.spacer.medium};
      }
    `;
  }};
`;

const DockedMenu = styled.div.attrs(({constants}: StyleProps) => ({
  constants
}))`
  ${({ theme, constants }: StyleProps) => {
    const con: Constants = constants as Constants;
    const top = con.headerHeight;
    return `
      display: flex;
      align-items: center;
      flex-direction: row;
      position: fixed;
      top: ${top}px;
      left: 0;
      width: 100%;
      z-index: 2;
      background-color: #E8EBF0;
      padding: 0 45px;
      height: ${constants?.dockedMenuHeight}px;
      
      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        top: ${constants?.headerHeightMobile}px;
        padding: 0 ${theme?.size.spacer.medium};
      }
    `;
  }};
`;

const DockedStepper = styled.div.attrs(({constants, isEmployee}: StyleProps) => ({
  constants,
  isEmployee
}))`
  ${({ theme, constants, isEmployee }: StyleProps) => {
    const con: Constants = constants as Constants;
    const top: number = isEmployee ? con.headerHeight + con.dockedWarningHeight + con.dockedAgentInfoHeight : con.headerHeight;
    const topMobile: number = isEmployee ? con.headerHeightMobile + con.dockedWarningHeight + con.dockedAgentInfoHeight : con.headerHeightMobile;

    return `
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      position: fixed;
      top: ${top}px;
      left: 0;
      width: 100%;
      z-index: 2;
      background-color: #FFFFFF;
      padding: 0 45px;
      height: ${constants?.dockedStepperHeight}px;
      overflow: auto;
      box-shadow: 0 2px 4px 0 hsl(205deg 99% 28% / 20%);

      li {
        &.stepper-item {
          button {
            white-space: nowrap;
          }
        }
      }

      nav {
        max-width: 100%;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        top: ${topMobile}px;
        padding: 0 ${theme?.size.spacer.medium};
      }
    `;
  }};
`;

const DockedWarning = styled.div.attrs(({constants, pathname, isAdmin}: StyleProps) => ({
  constants,
  pathname,
  isAdmin,
}))`
  ${({ theme, constants, pathname, isAdmin }: StyleProps) => {
    const con: Constants = constants as Constants;
    let top: number = 0;
    let topMobile: number = 0;

    if (['/search-agency-contact'].includes(pathname as string)) {
      top = con.headerHeight;
      topMobile = con.headerHeightMobile;
    } else if (['/agency-contacts'].includes(pathname as string)) {
      top = con.headerHeight + con.dockedMenuHeight;
      topMobile = con.headerHeightMobile + con.dockedMenuHeight;
    } else if (['/terminate-agency-contacts'].includes(pathname as string)) {
      top = con.headerHeight + con.dockedMenuHeight;
      topMobile = con.headerHeightMobile + con.dockedMenuHeight;
    } else if (['/agency-contact-profile'].includes(pathname as string) && !isAdmin) {
      top = con.headerHeight;
      topMobile = con.headerHeightMobile;
    } else if (['/agency-contact-profile'].includes(pathname as string) && isAdmin) {
      top = con.headerHeight + con.dockedMenuHeight;
      topMobile = con.headerHeightMobile + con.dockedMenuHeight;
    } else if (['/contact-listing'].includes(pathname as string)) {
      top = con.headerHeight;
      topMobile = con.headerHeightMobile;
    } else if (['/contact-types', '/general-details', '/agency-codes', '/primary-agency-code', '/user-privileges'].includes(pathname as string)) {
      top = con.headerHeight;
      topMobile = con.headerHeightMobile;
    }

    return `
      width: 100%;
      height: ${constants?.dockedWarningHeight}px;
      position: fixed;
      top: ${top}px;
      left: 0;
      z-index: 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #F5A524;
      color: ${theme?.colors.text};
      font-weight: ${theme?.font.primary.weight.bold};
      font-size: ${theme?.font.primary.size.small};
      line-height: 13px;

      @media only screen and (max-width: ${mobileWidth}px) {
        top: ${topMobile}px;
      }
    `;
  }};
`;

const DockedAgentInfo = styled.div.attrs(({constants, pathname, isAdmin, isAgencyOpsInternalUser}: StyleProps) => ({
  constants,
  pathname,
  isAdmin,
  isAgencyOpsInternalUser
}))`
  ${({ theme, constants, pathname, isAdmin, isAgencyOpsInternalUser }: StyleProps) => {
    const con: Constants = constants as Constants;
    let top: number = 0;
    let topMobile: number = 0;

    if (['/agency-contacts'].includes(pathname as string)) {
      top = con.headerHeight + con.dockedMenuHeight + con.dockedWarningHeight;
      topMobile = con.headerHeightMobile + con.dockedMenuHeight + con.dockedWarningHeight;
    } else if (isAgencyOpsInternalUser && ['/terminate-agency-contacts'].includes(pathname as string)) {
      top = con.headerHeight + con.dockedMenuHeight;
      topMobile = con.headerHeightMobile + con.dockedMenuHeight;
    } else if (['/terminate-agency-contacts'].includes(pathname as string)) {
      top = con.headerHeight + con.dockedMenuHeight + con.dockedWarningHeight;
      topMobile = con.headerHeightMobile + con.dockedMenuHeight + con.dockedWarningHeight;
    } else if (['/agency-contact-profile'].includes(pathname as string) && !isAdmin) {
      top = con.headerHeight + con.dockedWarningHeight;
      topMobile = con.headerHeightMobile + con.dockedWarningHeight;
    } else if (['/agency-contact-profile'].includes(pathname as string) && isAdmin) {
      top = con.headerHeight + con.dockedMenuHeight + con.dockedWarningHeight;
      topMobile = con.headerHeightMobile + con.dockedMenuHeight + con.dockedWarningHeight;
    } else if (['/contact-listing'].includes(pathname as string)) {
      top = con.headerHeight + con.dockedWarningHeight;
      topMobile = con.headerHeightMobile + con.dockedWarningHeight;
    } else if (['/contact-types', '/general-details', '/agency-codes', '/primary-agency-code', '/user-privileges'].includes(pathname as string)) {
      top = con.headerHeight + con.dockedWarningHeight;
      topMobile = con.headerHeightMobile + con.dockedWarningHeight;
    }

    return `
      width: 100%;
      height: ${constants?.dockedAgentInfoHeight}px;
      position: fixed;
      top: ${top}px;
      left: 0;
      z-index: 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #FFF6E9;
      color: ${theme?.colors.text};
      font-weight: ${theme?.font.primary.weight.bold};
      font-size: ${theme?.font.primary.size.small};
      line-height: 13px;
      border-radius: 3px 3px 0px 0px;
      padding-left: ${theme?.size.spacer.medium};
      padding-right: ${theme?.size.spacer.medium};

      @media only screen and (max-width: ${mobileWidth}px) {
        top: ${topMobile}px;
      }
    `;
  }};
`;

const AgentInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1170px;
`;

const Column = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      margin-right: 80px;
      color: ${theme?.colors.text};

      div:first-child {
        font-size: ${theme?.font.primary.size.smallTEMP};
        font-weight: ${theme?.font.primary.weight.normal};
        line-height: 16px;
      }

      div:last-child {
        font-size: ${theme?.font.primary.size.medium};
        font-weight: ${theme?.font.primary.weight.bold};
        line-height: 19px;
      }
    `;
  }};
`;

const Section = styled.section`
  display: flex;
  flex-direction: row;
`;

const DashboardIcon = styled.svg`
  ${({ theme }: StyleProps) => {
    return `
      margin-right: ${theme?.size.spacer.small};
    `;
  }};
`;

export default function Header({ theme, isAgencyOpsInternalUser, portal }: StyleProps) {
  const history = useHistory();
  const location = useLocation<AppContextProps>();
  const pathname = location.pathname;
  const isAdmin: boolean = decrypt(localStorage.getItem('isAdmin')) === 'true' ? true : false;
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;
  const onBehalfOf: string = getOnBehalfOf();
  const loginID: string = getLoginID();
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>('/');

  // Stepper
  const [activeStep, setActiveStep] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [skipped, setSkipped] = useState<Set<number>>(new Set());
  const steps = getSteps();
  const pathMap: PathMap[] = [
    { path: '/contact-types', step: 'Contact Type'},
    { path: '/general-details', step: 'General Details'},
    { path: '/agency-codes', step: 'Agency Code'},
    { path: '/primary-agency-code', step: 'Primary Agency Code'},
    { path: '/user-privileges', step: 'User Privileges'}
  ];
  const internalUserDescription = pathname === '/terminate-agency-contacts' && isAgencyOpsInternalUser ? '' : ` - Saving is disabled`;

  function getOnBehalfOf() {
    const personInfo: PersonInfo = JSON.parse(decrypt(localStorage.getItem('personInfo')));
    return personInfo ? `${personInfo.person_first_name} ${personInfo.person_last_name}` : '';
  }

  function getLoginID() {
    const personInfo: PersonInfo = JSON.parse(decrypt(localStorage.getItem('personInfo')));
    return personInfo ? personInfo.person_login_id : '';
  }

  function getIconHouse(colorKey: keyof ThemeColorsConfig) {
    return getDashboardIcon('15px', SAUX360Theme.colors[colorKey]);
  };

  const confirmModal = (event: React.MouseEvent, action: string) => {
    event.stopPropagation();
    if (action === 'close') {
      setDisplayModal(!displayModal);
    }
    if (action === 'discard') {
      localStorage.setItem('edit_state', encrypt('default'));
      setDisplayModal(!displayModal);
      if (redirectPath.includes('http')) {
        window.open(redirectPath, "_self");
      } else {
        history.push({
          pathname: `${redirectPath}`,
        });
      }
    }
  }

  function getDashboardIcon(size: string, color: string) {
    const renderSize = size;
    return (
      <DashboardIcon
        width={renderSize}
        height={renderSize}
        viewBox="0 0 60 45"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g transform="scale(4 4)">
          <path
            d="M0 5.95329L1.66111 4.54841L3.44759 3.13596L7.47767 0L15 6.00651L13.789 7L13 6.39483V10H11H3V5.54294L1.17558 6.97527L0 5.95329Z"
            fill={color}
          />
        </g>
      </DashboardIcon>
    );
  }

  function handleClickDashboard(e: React.MouseEvent) {
    const primaryAgencyCodeSaveButton = decrypt(localStorage.getItem('primaryAgencyCodeSaveButton'));
    const editState = decrypt(localStorage.getItem('edit_state'));
    if (editState === 'default' || (location.pathname !== '/agency-contact-profile' && location.pathname !== '/terminate-agency-contacts') || primaryAgencyCodeSaveButton === 'true') {
      history.push({
        pathname: `/agency-contacts`,
      });
    } else {
      if (editState !== 'default' && (location.pathname === '/agency-contact-profile' || location.pathname === '/terminate-agency-contacts')) {
        setRedirectPath('/agency-contacts');
        setDisplayModal(true);
      }
    }
  }

  function handleClickAgencyContactProfile(e: React.MouseEvent) {
    history.push({
      pathname: '/agency-contact-profile',
    });
  }

  function handleClickAgencyContactsTerminate(e: React.MouseEvent) {
    history.push({
      pathname: '/terminate-agency-contacts',
    });
  }

  function handleClickCommunicationPreferences(e: React.MouseEvent) {
    history.push({
      pathname: '/communication-preferences',
    })
  }

  function getSteps() {
    // return steps for the workflow
    const contact: NewContact = JSON.parse(decrypt(localStorage.getItem('NewContact')));
    if (contact !== null) {
      const assignmentLevels: string[] = [];
      contact.contactDetails.contactTypes.forEach((ct: NewContactContactType) => {
        switch (ct.contacttype) {
          case 3:
          case 7:
          case 10:
          case 13:
          case 14:
          case 36:
          case 37:
            // Agency Admin - 3
            // Agency Staff - 7
            // Agency Manager - 10
            // Primary Claim Contact - 13
            // Primary Mail Recipient - 14
            // Accounting Manager - 36
            // Licensed Producer - 37
            if (!assignmentLevels.includes('Agency Code')) {
              assignmentLevels.push('Agency Code');
            }
            break;
        }
      });

      const workflow: string [] = ['Contact Type', 'General Details', 'Agency Code', 'Primary Agency Code', 'User Privileges'];

      let pagesToSuppress: string[] = ['Agency Code'];

      pagesToSuppress = pagesToSuppress.filter((page: string) => !assignmentLevels.includes(page));

      pagesToSuppress.forEach((page: string) => {
        const index: number = workflow.indexOf(page);
        workflow.splice(index, 1);
      });

      return workflow;
    } else {
      return [];
    }
  }

  function isStepSkipped(step: number) {
    return skipped.has(step);
  }

  function handleClick(e: React.MouseEvent<HTMLElement>, index: number) {
    if (activeStep > index) {
      const steps = getSteps();
      const stepperStep = steps[index];
      
      pathMap.forEach(({ path, step }: PathMap) => {
        if (stepperStep === step) {
          history.push({
            pathname: path
          });
          return;
        }
      });
    }
  }

  function handleClickLogo(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const editState = decrypt(localStorage.getItem('edit_state'));
    if (editState === 'default' || (location.pathname !== '/agency-contact-profile' && location.pathname !== '/terminate-agency-contacts')) {
      window.open(configuration.saconnect, "_blank");
    } else if (editState !== 'default' && (location.pathname === '/agency-contact-profile' || location.pathname === '/terminate-agency-contacts')) {
      setRedirectPath(configuration.saconnect);
      setDisplayModal(true);
    }
  }

  function handleModify() {
    const intervalRef = JSON.parse(decrypt(localStorage.getItem('intervalRef')));
    if (intervalRef !== null) {
      clearInterval(intervalRef.current);
      console.log(`stop polling: ${intervalRef.current}`);
    }
    history.push({
      pathname: `/search-agency-contact`,
      state: {
        navigateTo: pathname
      }
    });
  }

  useEffect(() => {
    pathMap.forEach(({ path, step }: PathMap) => {
      if (pathname === path) {
        const steps = getSteps();
        const index = steps.indexOf(step);
        if (index > -1) {
          setActiveStep(index);
        } else {
          setActiveStep(0);
        }
        return;
      }
    });

    const isAdmin: boolean = decrypt(localStorage.getItem('isAdmin')) === 'true' ? true : false;
    const selectedPersonId = decrypt(localStorage.getItem('selectedPersonId'));
    if (['/agency-contacts', '/terminate-agency-contacts', '/contact-listing'].includes(pathname) && !isAdmin && selectedPersonId !== null) {
      history.push({
        pathname: '/agency-contact-profile',
      });
    } else if (['/agency-contacts', '/terminate-agency-contacts', '/contact-listing'].includes(pathname) && !isAdmin && selectedPersonId === null) {
      history.push({
        pathname: '/',
      });
    } else if (pathname === '/agency-contact-profile' && selectedPersonId === null) {
      history.push({
        pathname: '/',
      });
    } else if (pathname === '/search-agency-contact' && !isEmployee) {
      history.push({
        pathname: '/',
      });
    } else if (pathname === '/communication-preferences' && isEmployee) {
      history.push({
        pathname: '/'
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const element: HTMLElement | null = document.querySelector(`button[aria-current="step"]`);
    if (element !== null) {
      element.scrollIntoView();
    }
  }, [activeStep]);

  useEffect(() => {
    let isClick: boolean;
    let tab: boolean;

    function determineHeaderHeight() {
      let headerHeight: number;
      if (window.innerWidth <= mobileWidth) {
        if (['/search-agency-contact'].includes(pathname)) {
          headerHeight = constants.headerHeightMobile + constants.dockedWarningHeight;
        } else if (['/agency-contacts'].includes(pathname)) {
          headerHeight = isEmployee
          ? constants.headerHeightMobile + constants.dockedMenuHeight + constants.dockedWarningHeight + constants.dockedAgentInfoHeight
          : constants.headerHeightMobile + constants.dockedMenuHeight;
        } else if (['/agency-contact-profile'].includes(pathname)) {
          if (isEmployee && isAdmin) {
            headerHeight = constants.headerHeightMobile + constants.dockedMenuHeight + constants.dockedWarningHeight + constants.dockedAgentInfoHeight;
          } else if (isEmployee && !isAdmin) {
            headerHeight = constants.headerHeightMobile + constants.dockedWarningHeight + constants.dockedAgentInfoHeight;
          } else if (!isEmployee && isAdmin) {
            headerHeight = constants.headerHeightMobile + constants.dockedMenuHeight;
          } else if (!isEmployee && !isAdmin) {
            headerHeight = constants.headerHeightMobile;
          } else {
            headerHeight = 0;
          }
        } else if (['/communications-preferences'].includes(pathname)) {
          headerHeight = constants.headerHeightMobile;
        } else if (['/contact-types', '/general-details', '/agency-codes','/primary-agency-code', '/user-privileges'].includes(pathname)) { 
          headerHeight = isEmployee
          ? constants.headerHeightMobile + constants.dockedWarningHeight + constants.dockedAgentInfoHeight + constants.dockedStepperHeight 
          : constants.headerHeightMobile + constants.dockedStepperHeight;
        } else {
          headerHeight = 0;
        }
      } else {
        if (['/search-agency-contact'].includes(pathname)) {
          headerHeight = constants.headerHeight + constants.dockedWarningHeight;
        } else if (['/agency-contacts'].includes(pathname)) {
          headerHeight = isEmployee
          ? constants.headerHeight + constants.dockedMenuHeight + constants.dockedWarningHeight + constants.dockedAgentInfoHeight
          : constants.headerHeight + constants.dockedMenuHeight;
        } else if (['/agency-contact-profile'].includes(pathname)) {
          if (isEmployee && isAdmin) {
            headerHeight = constants.headerHeight + constants.dockedMenuHeight + constants.dockedWarningHeight + constants.dockedAgentInfoHeight;
          } else if (isEmployee && !isAdmin) {
            headerHeight = constants.headerHeight + constants.dockedWarningHeight + constants.dockedAgentInfoHeight;
          } else if (!isEmployee && isAdmin) {
            headerHeight = constants.headerHeight + constants.dockedMenuHeight;
          } else if (!isEmployee && !isAdmin) {
            headerHeight = constants.headerHeight;
          } else {
            headerHeight = 0;
          }
        } else if (['/communications-preferences'].includes(pathname)) {
          headerHeight = constants.headerHeight;
        } else if (['/contact-types', '/general-details', '/agency-codes','/primary-agency-code', '/user-privileges'].includes(pathname)) { 
          headerHeight = isEmployee
          ? constants.headerHeight + constants.dockedWarningHeight + constants.dockedAgentInfoHeight + constants.dockedStepperHeight
          : constants.headerHeight + constants.dockedStepperHeight;
        } else {
          headerHeight = 0;
        }
      }
      return headerHeight;
    }

    function handleFocus(e: any) {
      const headerHeight = determineHeaderHeight();
      if (e.target && !isClick && tab) {
        const elementRect = e.target.getBoundingClientRect();
        if (elementRect.top < headerHeight) {
          if ((elementRect.top + elementRect.height) > headerHeight) {
            // Element is partially obscurred - scroll into view
            window.scrollBy(0, elementRect.top - headerHeight);
          } else {
            // Element is completely obscurred - scroll into center
            e.target.scrollIntoView({
              block: "center"
            });
          }
        }
      }
    }

    window.addEventListener('focusin', handleFocus);
    window.addEventListener('mousedown', (e) => {
      isClick = true;
      tab = false;
    });
    window.addEventListener('keydown', (e) => {
      isClick = false;
      tab = e.key === 'Tab';
    });

    return () => {
      window.removeEventListener('focusin', handleFocus);
      window.removeEventListener('mousedown', (e) => {});
      window.removeEventListener('keydown', (e) => {});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Allow tab key to change intent to "keyboard"
    whatInput.specificKeys([9]);
  }, []);

  useEffect(() => {
    if (displayModal) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [displayModal]);

  if (portal?.isDisable) {
    return (
      <>
        <DockedHeader onClick={handleClickLogo} />
        <DisabledPortalBanner constants={constants}>
          <SAText type="heading-3" colorVariant="active-foreground">
            {portal.message}
          </SAText>
        </DisabledPortalBanner>
      </>
    );
  }

  return (
    <>
      <DockedHeader onClick={handleClickLogo} />
      {pathname === '/agency-contacts' &&
        <DockedMenu constants={constants}>
          <SAButton label="Dashboard" startIcon={getIconHouse('text')} onClick={handleClickDashboard} variant="link-small" color="text" textTransform="none" />
        </DockedMenu>
      }
      {pathname === '/agency-contact-profile' && isAdmin &&
        <DockedMenu constants={constants}>
          <SAButton label="Dashboard" startIcon={getIconHouse('primary')} onClick={handleClickDashboard} variant="link-small" color="primary" textTransform="none" />
          <SAIcon icon={SAIcons.chevronRight} size={SAIconSize.small} colorVariant="black" />
          <SAButton label="Agency Contact Profile" onClick={handleClickAgencyContactProfile} variant="link-small" color="text" textTransform="none" />
        </DockedMenu>
      }
      {pathname === '/terminate-agency-contacts' && isAdmin &&
        <DockedMenu constants={constants}>
          <SAButton label="Dashboard" startIcon={getIconHouse('primary')} onClick={handleClickDashboard} variant="link-small" color="primary" textTransform="none" />
          <SAIcon icon={SAIcons.chevronRight} size={SAIconSize.small} colorVariant="black" />
          <SAButton label="Terminate Agency Contacts" onClick={handleClickAgencyContactsTerminate} variant="link-small" color="text" textTransform="none" />
        </DockedMenu>
      }
      {(pathname === '/search-agency-contact' ||
        pathname === '/agency-contacts' ||
        pathname === '/agency-contact-profile' ||
        pathname === '/contact-types' ||
        pathname === '/general-details' ||
        pathname === '/agency-codes' ||
        pathname === '/primary-agency-code' ||
        pathname === '/user-privileges' ||
        pathname === '/contact-listing' ||
        pathname === '/terminate-agency-contacts') && isEmployee &&
        <DockedWarning constants={constants} pathname={pathname} isAdmin={isAdmin}>Internal User { internalUserDescription }</DockedWarning>
      }
      {(pathname === '/agency-contacts' ||
        pathname === '/agency-contact-profile' ||
        pathname === '/contact-types' ||
        pathname === '/general-details' ||
        pathname === '/agency-codes' ||
        pathname === '/primary-agency-code' ||
        pathname === '/user-privileges' ||
        pathname === '/contact-listing' ||
        pathname === '/terminate-agency-contacts') && isEmployee &&
        <DockedAgentInfo constants={constants} pathname={pathname} isAdmin={isAdmin}>
          <AgentInfoContainer>
            <Section>
              <Column>
                <div>
                  On Behalf Of
                </div>
                <div>
                  {onBehalfOf}
                </div>
              </Column>
              <Column>
                <div>
                  Login ID
                </div>
                <div>
                  {loginID}
                </div>
              </Column>
            </Section>
            <Section>
              <SAButton variant="small-outline" label="Modify" color="black" textTransform="uppercase" onClick={handleModify} />
            </Section>
          </AgentInfoContainer>
        </DockedAgentInfo>
      }
      {displayModal && (
        <ConfirmEditModal confirmModal={confirmModal} />
      )}
      {(pathname === '/contact-types' ||
        pathname === '/general-details' ||
        pathname === '/agency-codes' ||
        pathname === '/primary-agency-code' ||
        pathname === '/user-privileges') &&
        <DockedStepper constants={constants} isEmployee={isEmployee}>
          <SAStepper activeStep={activeStep} hideNumbers>
            {steps.map((label, index) => {
              const stepProps: any = {};
              if (activeStep === index) {
                stepProps.active = true;
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              } else if (activeStep > index) {
                stepProps.completed = true;
              }
              if (activeStep < index) {
                stepProps.disabled = true;
              }
              return (
                <SAStep key={label} {...stepProps}>
                  <SAButton
                    label={label}
                    textTransform="none"
                    onClick={(e) => handleClick(e, index)}
                    variant="secondary-link-small"
                    disabled={activeStep < index}
                  />
                </SAStep>
              );
            })}
          </SAStepper>
        </DockedStepper>
      }
    </>
  );
}
